.ui.card.withMarker, .ui.cards>.card.withMarker {
    display: flex;
    flex-direction: row;
}

.ui.card>.marker, .marker {
    width: 8px;
    flex: 0 0 auto;
    background: #4caf50;
    border-radius: 12px !important;
    float: left;
    margin: 1em 0em 1em 1em;
}

.ui.card.innerCard, .ui.cards>.card.innerCard  {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ui.card.innerCard>.content, .ui.cards>.card.innerCard>.content {
    border: none;
    box-shadow: none;
    padding: 12px;
}
