body {
  background: #ebeeef;
}

/* Fix dropdown menu under dimmer overlay */
.ui.dimmer {
  z-index: 9;
}

.ui.dimmer.modals {
  z-index: 1000;
}

.overview-waiting-state {
  animation-name: overview-waiting;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
  transform: translate3d(0, 0, 0);
}

@keyframes overview-waiting {
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.menu-alarm-state {
  animation: alarm-red;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
  transform: translate3d(0, 0, 0);
}

.kanban-alarm-state {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
  transform: translate3d(0, 0, 0);
}

.ui.active.progress .bar:after {
  animation: none;
}

@keyframes alarmbackground {
  to {
    background-color: #ffecec;
  }
}

@keyframes alarm-red {
  to {
    background-color: #ffecec;
  }
}

@keyframes alarm-orange {
  to {
    background-color: #fbd3bb;
  }
}

@keyframes alarm-yellow {
  to {
    background-color: #feeab7;
  }
}

@keyframes alarm-green {
  to {
    background-color: #bce9c6;
  }
}

@keyframes alarm-blue {
  to {
    background-color: #bcd9ef;
  }
}

@keyframes alarm-violet {
  to {
    background-color: #cfc2ee;
  }
}

@keyframes alarm-purple {
  to {
    background-color: #e1c1ed;
  }
}

@keyframes alarm-pink {
  to {
    background-color: #f5c2de;
  }
}

@keyframes alarm-brown {
  to {
    background-color: #e2d0c4;
  }
}

@keyframes alarm-grey {
  to {
    background-color: #d4d4d4;
  }
}

@keyframes alarm-black {
  to {
    background-color: #bbbcbb;
  }
}
/* Indicating */
.ui.indicating.reverse.progress[data-percent^="2"] .bar,
.ui.indicating.reverse.progress[data-percent^="1"] .bar {
  background-color: #66da81;
}
.ui.indicating.reverse.progress[data-percent^="4"] .bar,
.ui.indicating.reverse.progress[data-percent^="3"] .bar {
  background-color: #b4d95c;
}
.ui.indicating.reverse.progress[data-percent^="5"] .bar {
  background-color: #ddc928;
}
.ui.indicating.reverse.progress[data-percent^="7"] .bar,
.ui.indicating.reverse.progress[data-percent^="6"] .bar {
  background-color: #e6bb48;
}
.ui.indicating.reverse.progress[data-percent^="8"] .bar {
  background-color: #efbc72;
}
.ui.indicating.reverse.progress[data-percent^="100"] .bar,
.ui.indicating.reverse.progress[data-percent^="9"] .bar {
  background-color: #d95c5c;
}

/* Indicating Label */
.ui.indicating.reverse.progress[data-percent^="2"] .label,
.ui.indicating.reverse.progress[data-percent^="1"] .label {
  color: rgba(0, 0, 0, 0.87);
}
.ui.indicating.reverse.progress[data-percent^="4"] .label,
.ui.indicating.reverse.progress[data-percent^="3"] .label {
  color: rgba(0, 0, 0, 0.87);
}
.ui.indicating.reverse.progress[data-percent^="5"] .label {
  color: rgba(0, 0, 0, 0.87);
}
.ui.indicating.reverse.progress[data-percent^="7"] .label,
.ui.indicating.reverse.progress[data-percent^="6"] .label {
  color: rgba(0, 0, 0, 0.87);
}
.ui.indicating.reverse.progress[data-percent^="8"] .label {
  color: rgba(0, 0, 0, 0.87);
}
.ui.indicating.reverse.progress[data-percent^="100"] .label,
.ui.indicating.reverse.progress[data-percent^="9"] .label {
  color: rgba(0, 0, 0, 0.87);
}

div[tabindex="-1"]:focus {
  outline: 0;
}
